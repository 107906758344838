<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-06-10 16:14:49
 * @LastEditors: hutian
 * @LastEditTime: 2021-06-17 09:12:08
-->
<template>
  <div class="">
      <ProductListPage :pageType="'audited'" :status="[3,4]"></ProductListPage>
  </div>
</template>

<script>
import ProductListPage from './ProductListPage'
export default {
  name: 'product_goods',
  data() {
    return {}
  },

  components: {
    ProductListPage,
  },

  computed: {},

  created() {},

  methods: {},
}
</script>

<style lang='scss' scoped>
</style>